import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../../services/content-models-service/ContentModelService';
import {
	extractContentModeBasedOnUrl,
	extractMainContentTypeBasedOnUrl,
	isEditScreen,
} from '../../../../../../global-helpers/global.helpers';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { updateContentUpdatedDateTimeReduxState, updateDateTimeReduxState } from '../../helpers/date-time-select.helper';
import DateTimeSelect from './date-time-select';
import { ReduxGeneralContentProps } from '../../constants/redux-general-content.attributes';
import '../../../../.././Pages/./Live Blog/./components/./subcomponents/./live-blog-updated-at-select/./styles/./style.scss';

type Properties = {
	t: any;
	publishedFrom: string;
	publishedUntil: string;
	updateTempProperty: (propertyObject: Record<string, any>) => void;
	contentUpdatedAt: string;
};

const DateTimesWrapper: FunctionComponent<Properties> = ({ t, publishedFrom, publishedUntil, updateTempProperty, contentUpdatedAt }) => {
	const [toggleInfoPanel, setToggleInfoPanel] = useState<boolean>(false);

	const onPublishedFromChange = (dates: Date[] | null) =>
		updateDateTimeReduxState(dates, updateTempProperty, ReduxGeneralContentProps.PUBLISHED_AT);
	const onPublishedUntilChange = (dates: Date[] | null) =>
		updateDateTimeReduxState(dates, updateTempProperty, ReduxGeneralContentProps.PUBLISHED_UNTIL);
	const onUpdatedAtChange = (dates: Date[] | null) =>
		updateContentUpdatedDateTimeReduxState(dates, updateTempProperty, ReduxGeneralContentProps.CONTENT_UPDATED_AT);

	const onInfoHover = () => setToggleInfoPanel(!toggleInfoPanel);

	return (
		<>
			<Row>
				<Col>
					<FormGroup>
						<Label htmlFor={DATA_QA_ATTRIBUTES.PUBLISHED_FROM}>{t('published_from')}</Label>
						<DateTimeSelect t={t} date={publishedFrom} onChange={onPublishedFromChange} id={DATA_QA_ATTRIBUTES.PUBLISHED_FROM} />
					</FormGroup>
				</Col>
				<Col>
					<FormGroup>
						<Label htmlFor={DATA_QA_ATTRIBUTES.PUBLISHED_TO}>{t('published_until')}</Label>
						<DateTimeSelect t={t} date={publishedUntil} onChange={onPublishedUntilChange} id={DATA_QA_ATTRIBUTES.PUBLISHED_TO} />
					</FormGroup>
				</Col>
			</Row>
			{isEditScreen() ? (
				<Row>
					<Col>
						<FormGroup>
							<Label htmlFor={DATA_QA_ATTRIBUTES.CONTENT_UPDATED_AT}>{t('content_updated_at')}</Label>
							<div className='updated-at-info'>
								<div className='info-icon' onClick={onInfoHover} />
								{toggleInfoPanel && (
									<div className='info-message'>
										<div className='info-message-description'> {t('updated_at_info_description')} </div>
									</div>
								)}
							</div>
							<DateTimeSelect t={t} date={contentUpdatedAt} onChange={onUpdatedAtChange} id={DATA_QA_ATTRIBUTES.CONTENT_UPDATED_AT} />
						</FormGroup>
					</Col>
				</Row>
			) : null}
		</>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		contentUpdatedAt: state[tempPropertyNaming][nestedTempPropertyNaming].contentUpdatedAt || '',
		publishedFrom: state[tempPropertyNaming][nestedTempPropertyNaming].publishedAt || '',
		publishedUntil: state[tempPropertyNaming][nestedTempPropertyNaming].publishedUntil || '',
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DateTimesWrapper);
