import React, { FunctionComponent, useState } from 'react';
import LiveBlogModel from '../../../models/live-blog.model';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import DateTimeSelect from '../../../../../Partials/Sidebar/general-content/subcomponents/date-time/date-time-select';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './styles/style.scss';

type Properties = {
	liveBlog: LiveBlogModel;
	setLiveUpdatedAtTime: (date: string | null) => void;
};

export const LiveBlogUpdatedAtTimeSelect: FunctionComponent<Properties> = ({ liveBlog, setLiveUpdatedAtTime }) => {
	const { t } = useTranslation();
	const startDate = moment(liveBlog.content_updated_at ? liveBlog.content_updated_at : '').toISOString();
	const [toggleInfoPanel, setToggleInfoPanel] = useState<boolean>(false);

	const onUpdatedAtTimeSelect = (dates: Date[] | null) => {
		const selectedDate = dates && dates.length >= 0 ? dates[0] : '';
		setLiveUpdatedAtTime(moment(selectedDate).toISOString());
	};

	const onInfoHover = () => setToggleInfoPanel(!toggleInfoPanel);

	return (
		<Row className='mb-3'>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_UPDATED_AT_TIME}>
						<strong>{t('content_updated_at')}</strong>
					</Label>
					<div className='updated-at-info'>
						<div className='info-icon' onClick={onInfoHover} />
						{toggleInfoPanel && (
							<div className='info-message'>
								<div className='info-message-description'> {t('updated_at_info_description')} </div>
							</div>
						)}
					</div>
					<DateTimeSelect
						t={t}
						date={liveBlog.content_updated_at ? liveBlog.content_updated_at : ''}
						onChange={onUpdatedAtTimeSelect}
						id={DATA_QA_ATTRIBUTES.LIVE_BLOG_UPDATED_AT_TIME}
						minDate={startDate ? startDate : ''}
					/>
				</FormGroup>
			</Col>
		</Row>
	);
};
