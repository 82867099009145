export enum DATA_QA_ATTRIBUTES {
	GENERAL_CONTENT_WRAPPER = 'sidebar-general-content-wrapper',
	MAIN_CATEGORY = 'sidebar-general-content-main-category-select',
	ADDITIONAL_CATEGORY = 'sidebar-general-content-additional-category-select',
	AUTHOR = 'sidebar-general-content-author-select',
	CUSTOM_AUTHOR = 'sidebar-general-content-custom-author-text',
	STATUS = 'sidebar-general-content-status-select',
	PROPERTIES = 'sidebar-general-content-properties-wrapper',
	TYPE = 'sidebar-general-content-type-select',
	PUBLISHED_FROM = 'sidebar-general-content-published-from-date',
	CONTENT_UPDATED_AT = 'sidebar-general-content-content-updated-at-date',
	PUBLISHED_TO = 'sidebar-general-content-published-to-date',
	DISTRIBUTION_REGIONS = 'sidebar-general-content-distribution-regions-select',
	DISTRIBUTION_CHANNELS = 'sidebar-general-content-distribution-channels-select',
	ORIGIN = 'sidebar-general-content-origin-select',
	COMMENTS_POLICIES = 'sidebar-general-content-comments-policies-select',
	LIVE_BLOG_CATEGORY = 'live-blog-main-category-select',
	ADDITIONAL_LIVE_BLOG_CATEGORY = 'live-blog-additional-category-select',
	LIVE_BLOG_TITLE = 'live-blog-title-field',
	LIVE_BLOG_DESCRIPTION = 'live-blog-description-field',
	LIVE_BLOG_COLLABORATORS = 'live-blog-collaborators-select',
	LIVE_BLOG_AUTHORS = 'live-blog-authors-select',
	LIVE_BLOG_SLUG = 'live-blog-slug-select',
	LIVE_BLOG_START_TIME = 'live-blog-start-time-select',
	LIVE_BLOG_END_TIME = 'live-blog-end-time-select',
	LIVE_BLOG_UPDATED_AT_TIME = 'live-blog-updated-at-time-select',
	LIVE_BLOG_IMAGE_UPLOAD_CONTAINER = 'live-blog-image-upload-container',
	LIVE_BLOG_IMAGE_UPLOAD_CONTAINER_OPERATIONS = 'live-blog-image-upload-container-operations',
	LIVE_BLOG_IMAGE_SEARCH = 'live-blog-image-search-listing-select',
	LIVE_BLOG_IMAGE_SEARCH_BUTTON = 'live-blog-image-search-button',
	LIVE_BLOG_IMAGE_ADVANCED_SEARCH_BUTTON = 'live-blog-image-advanced-search-button',
	LIVE_BLOG_TOGGLE_IMAGES = 'live-blog-toggle-images',
	LIVE_BLOG_IMAGE_LISTING_CONTAINER = 'live-blog-image-listing-container',
	LIVE_BLOG_MAIN_IMAGE = 'live-blog-main-media',
	LIVE_BLOG_STATUS = 'live-blog-status-select',
	LIVE_BLOG_TYPE = 'live-blog-type-select',
	LIVE_BLOG_SPORTS = 'live-blog-sport-select',
	LIVE_BLOG_TOURNAMENTS = 'live-blog-tournaments-select',
	LIVE_BLOG_TEAMS = 'live-blog-teams-select',
	LIVE_BLOG_EVENTS = 'live-blog-events-select',
	LIVE_BLOG_LANGUAGE = 'live-blog-language-select',
	LIVE_BLOG_PAGINATION = 'live-blog-pagination-select',
	LIVE_BLOG_MATCH_HEADER = 'live-blog-match-header-toggle',
	LIVE_BLOG_STEPPER = 'live-blog-stepper-component',
	LIVE_BLOG_MAIN_MEDIA_TYPE = 'live-blog-main-media-type-select',
	LIVE_BLOG_MAIN_MEDIA_EXTERNAL = 'live-blog-main-media-external-select',
	LIVE_BLOG_MAIN_IMAGE_LISTING_MODAL = 'live-blog-main-image-listing-modal',
	LIVE_BLOG_MAIN_IMAGE_LISTING_MODAL_SAVE = 'live-blog-main-image-listing-modal-save-button',
	LIVE_BLOG_MAIN_IMAGE_LISTING_MODAL_CLOSE = 'live-blog-main-image-listing-modal-close-button',
	LIVE_BLOG_COLLABORATORS_MODAL = 'live-blog-collaborators-modal',
	LIVE_BLOG_COLLABORATORS_MODAL_CLOSE = 'live-blog-collaborators-modal-close-button',
	LIVE_BLOG_COLLABORATORS_MODAL_ADD = 'live-blog-collaborators-modal-save-add',
	LIVE_BLOG_ADVANCED_SEARCH_DATE = 'live-blog-advanced-search-date-select',
	LIVE_BLOG_SPONSORS = 'live-blog-sponsors-select',
	LIVE_BLOG_COLLABORATORS_ADDITIONAL_INFO = 'live-blog-collaborators-additional-info-select',
	LIVE_BLOG_LIST = 'live-blog-list-select',
	LIST_CONTAINER = 'live-blog-list-content',
	LIST_PRESENT_IN_CONTAINER = 'live-blog-list-content-present-in',
	LIST_MODAL = 'live-blog-list-modal',
	LIST_SELECT = 'live-blog-list-select',
	LIST_SHOW_IMAGES = 'live-blog-list-content-show-images',
	LIVE_BLOG_LIST_MODAL_CLOSE = 'live-blog-list-modal-close-button',
	LIVE_BLOG_LIST_MODAL_ADD = 'live-blog-list-modal-add-button',
	LIVE_BLOG_LIST_SAVE = 'live-blog-list-modal-save-button',
	LIVE_BLOG_EVENTS_MODAL = 'live-blog-events-modal',
	LIVE_BLOG_EVENT_MODAL_ADD = 'live-blog-events-modal-save-add',
	LIVE_BLOG_EVENT_MODAL_CLOSE = 'live-blog-events-modal-close-button',
	LIVE_BLOG_LIST_ADD_ITEM_TO_TOP = 'live-blog-list-items-add-to-top-button',
	LIVE_BLOG_SORTABLE_LIST = 'live-blog-sortable-list',
	LIVE_BLOG_SORTABLE_ITEM = 'live-blog-sortable-item',
	LIVE_BLOG_SEO_COMPONENT = 'live-blog-seo-component',
	LIVE_BLOG_SEO_TITLE = 'live-blog-seo-title',
	LIVE_BLOG_SEO_GENERATE_TITLE = 'live-blog-generate-seo-title-automatically',
	LIVE_BLOG_SEO_DESCRIPTION = 'live-blog-seo-description',
}
